import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

setTimeout(function() {
  window.location.href = "https://www.your-destination-website.com";
}, 30000); // 30000 milliseconds = 30 seconds


reportWebVitals();
